import { useEffect, useState } from "preact/hooks";
import { Server } from '../../utils/types.ts';
import AnalyzerJWT from '../AnalyzerJWT.tsx';

interface ServerConfigHeaderOAuthProps {
  serverId: string;
}

export default function ServerConfigHeaderOAuth({ serverId }: ServerConfigHeaderOAuthProps) {
  const [server, setServer] = useState<Server | null>(null);
  const [loadingServer, setLoadingServer] = useState(false);
  const [originalToken, setOriginalToken] = useState<string>("");  // Original token from the server
  const [editableToken, setEditableToken] = useState<string>("");  // Editable token for the user
  const [tokenStatus, setTokenStatus] = useState<string | null>(null);
  const [tokenColor, setTokenColor] = useState<string>("text-gray-600");
  const [showAnalyzer, setShowAnalyzer] = useState(false);
  const [isTokenChanged, setIsTokenChanged] = useState(false); // Track if token is changed

  // Helper function to decode JWT and check token validity
  const analyzeToken = (token: string) => {
    try {
      const parts = token.split(".");
      if (parts.length !== 3) throw new Error("Invalid token format");

      const payload = JSON.parse(atob(parts[1]));
      const exp = payload.exp;

      if (!exp) throw new Error("Token has no expiration field");

      const now = Math.floor(Date.now() / 1000);
      const timeLeft = exp - now;

      if (timeLeft > 0) {
        const expirationDate = new Date(exp * 1000).toLocaleString();
        setTokenStatus(`Token is valid until ${expirationDate}`);
        setTokenColor("text-green-600");
      } else {
        const expiredDate = new Date(exp * 1000).toLocaleString();
        setTokenStatus(`Token expired on ${expiredDate}`);
        setTokenColor("text-red-600");
      }
    } catch (error) {
      setTokenStatus("Invalid token");
      setTokenColor("text-red-600");
    }
  };

  // Fetch server information and token once when component is mounted
  useEffect(() => {
    async function fetchServer() {
      setLoadingServer(true);
      setTokenStatus(null);
      setShowAnalyzer(false);

      try {
        const res = await fetch(`/api/load-server?serverId=${serverId}`);
        if (!res.ok) throw new Error(`Failed to load server: ${res.statusText}`);
        const serverData = await res.json();
        setServer(serverData);

        // After fetching the server, fetch the token from `server-token.json`
        const tokenRes = await fetch(`/api/load-token?serverId=${serverId}`);
        if (!tokenRes.ok) throw new Error(`Failed to load token: ${tokenRes.statusText}`);
        const tokenData = await tokenRes.json();
        setOriginalToken(tokenData.token);  // Store the original token
        setEditableToken(tokenData.token);  // Set the editable token

        if (tokenData.token) {
          analyzeToken(tokenData.token);  // Analyze the token immediately
        } else {
          setTokenStatus("No token available");
          setTokenColor("text-gray-600");
        }
      } catch (error) {
        setTokenStatus("Error loading token information");
        setTokenColor("text-red-600");
      } finally {
        setLoadingServer(false);
      }
    }

    if (serverId) {
      fetchServer();
    }
  }, [serverId]);

  // Handle changes in the token input and analyze it immediately
  const handleTokenChange = (newToken: string) => {
    setEditableToken(newToken);  // Update the editable token
    analyzeToken(newToken);  // Re-analyze the token on every change
    setIsTokenChanged(newToken !== originalToken);  // Check if token has changed from original
  };

  // Save token to `server-token.json` for this serverId
  const handleSaveToken = async () => {
    try {
      const res = await fetch(`/api/save-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ serverId, token: editableToken }),  // Save the edited token
      });
      if (!res.ok) throw new Error(`Failed to save token: ${res.statusText}`);

      // Once saved, re-analyze the token
      setTokenStatus("Token saved successfully");
      setTokenColor("text-green-600");
      setOriginalToken(editableToken);  // Update the original token after saving
      setIsTokenChanged(false);  // Reset the change flag after saving
    } catch (error) {
      setTokenStatus("Error saving token");
      setTokenColor("text-red-600");
    }
  };

  return (
    <>
      {loadingServer ? (
        <p>Loading server information...</p>
      ) : server ? (
        <div class="mb-4">
          <h2 class="text-2xl font-bold">{server.name}</h2>
          <p class="text-sm">URL: {server.url}</p>
          <p class="text-sm">Description: {server.description}</p>
          
          {/* Editable token input */}
          <div class="mt-2">
            <label class="block text-sm font-medium">Token</label>
            <input
              type="text"
              value={editableToken}
              onChange={(e) => handleTokenChange(e.currentTarget.value)}  // Analyze the token on change
              class="w-full p-2 border rounded-md"
            />
          </div>

          {/* Token status and analyzer */}
          <div class="flex items-center space-x-4 mt-2">
            <p class={`text-sm ${tokenColor}`}>{tokenStatus}</p>
            <button
  onClick={handleSaveToken}  // Save the token when clicked
  class={`px-3 py-1 rounded-md shadow-sm transition duration-200 ${
    isTokenChanged 
      ? "bg-green-500 text-white hover:bg-green-400 cursor-pointer"  // Active state with pointer cursor
      : "bg-gray-400 text-white cursor-not-allowed"  // Disabled state with not-allowed cursor
  }`}
  disabled={!isTokenChanged}  // Disable the button when no changes
>
  Save Token
</button>

            {editableToken && (
              <button
                type="button"
                onClick={() => setShowAnalyzer(!showAnalyzer)}  // Toggle the JWT analyzer
                class="bg-blue-500 text-white px-3 py-1 rounded-md shadow-sm hover:bg-blue-400 transition duration-200"
              >
                {showAnalyzer ? "Hide Token Analyzer" : "Analyze Token"}
              </button>
            )}
          </div>

          {/* Conditionally render the JWT analyzer */}
          {showAnalyzer && editableToken && <AnalyzerJWT token={editableToken} />}
        </div>
      ) : (
        <p>No server information available.</p>
      )}
    </>
  );
}
